import { defineStore } from "pinia";

// match messages that look like this so we can filter out the api/v1 prefix
// [POST] "/api/v1/vehicles/plateLookup": 400 Plate invalid or not found
const apiMatchRegex = /\/api\/v1.*: \d{3} (.*)/;

export const useAlertStore = defineStore("alert", {
  state: () => ({
    alertMessage: "",
  }),

  getters: {
    showAlert: (state) => {
      return !!state.alertMessage && state.alertMessage !== "";
    },
  },

  actions: {
    setAlert(message: string) {
      const apiMsgMatch = message.match(apiMatchRegex);
      if (apiMsgMatch) {
        this.alertMessage = apiMsgMatch[1];
      } else {
        this.alertMessage = message;
      }
    },
    clearAlert() {
      this.alertMessage = "";
    },
  },
});
