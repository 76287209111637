import { ref, markRaw, watch, isRef, unref, defineAsyncComponent } from "vue";

const MileageTrimZipInput = defineAsyncComponent(() => import("../components/MileageTrimZipInput.vue"));
const SelectOptions = defineAsyncComponent(() => import("../components/SelectOptions.vue"));
const VehicleCondition = defineAsyncComponent(() => import("../components/VehicleCondition.vue"));
const VehicleAdditionalDetail = defineAsyncComponent(() => import("../components/VehicleAdditionalDetail.vue"));
const VehicleFinance = defineAsyncComponent(() => import("../components/VehicleFinance.vue"));
const ShowValuation = defineAsyncComponent(() => import("../components/ShowValuation.vue"));
const AcceptOffer = defineAsyncComponent(() => import("../components/AcceptOffer.vue"));

// workflow starter inputs
const VinWorkflow = defineAsyncComponent(() => import("../components/VinWorkflow.vue"));
const PlateWorkflow = defineAsyncComponent(() => import("../components/PlateWorkflow.vue"));
const YmmWorkflow = defineAsyncComponent(() => import("../components/YmmWorkflow.vue"));

export const useWorkflow = () => {
  type WorkflowDef = {
    name: string;
    title: string;
    component: any;
  };
  const vinWorkflowDef = ref<WorkflowDef>({ name: "VIN", title: "VIN", component: markRaw(VinWorkflow) });
  const plateWorkflowDef = ref<WorkflowDef>({ name: "Plate", title: "License Plate", component: markRaw(PlateWorkflow) });
  const ymmWorkflowDef = ref<WorkflowDef>({ name: "YMM", title: "Make & Model", component: markRaw(YmmWorkflow) });

  const workflows = ref([
    vinWorkflowDef.value,
    plateWorkflowDef.value,
    // ymmWorkflowDef.value // remove from app?
  ]);

  const WorkflowSteps = ref([
    {
      icon: "",
      name: "MileageTrimZipInput",
      title: "Enter your vehicle's mileage, trim, &&nbsp;your zip&nbsp;code",
      subtitle: "This will ensure an accurate quote.",
      component: markRaw(MileageTrimZipInput),
      completed: false,
    },
    {
      icon: "",
      name: "SelectOptions",
      title: "Select your vehicle's color",
      subtitle: "",
      component: markRaw(SelectOptions),
      completed: false,
    },
    {
      icon: "",
      name: "VehicleCondition",
      title: "What is your vehicle's current condition?",
      subtitle: "",
      component: markRaw(VehicleCondition),
      completed: false,
    },
    {
      icon: "",
      name: "VehicleAdditionalDetail",
      title: "",
      subtitle: "",
      component: markRaw(VehicleAdditionalDetail),
      completed: false,
    },
    {
      icon: "",
      name: "VehicleFinance",
      title: "",
      subtitle: "",
      component: markRaw(VehicleFinance),
      completed: false,
    },
    {
      icon: "",
      name: "EstimatedVehicleValue",
      title: "Your estimated vehicle value",
      subtitle: "",
      component: markRaw(ShowValuation),
      completed: false,
    },
    // {
    //   icon: "",
    //   name: "SubmitOffer",
    //   title: "Enter your contact information",
    //   subtitle: "",
    //   component: markRaw(AcceptOffer),
    //   completed: false,
    // },
  ]);

  const selectedWorkflowIndex = ref(0);
  const selectedWorkflow = ref<WorkflowDef>(workflows.value[0]);

  const selectedWorkflowSteps = WorkflowSteps;

  const setWorkflow = (workflowName: string) => {
    const wfIdx = workflows.value.findIndex((wf) => wf.name === workflowName);
    if (wfIdx === -1) {
      throw new Error("Invalid workflow name");
    }
    selectedWorkflowIndex.value = wfIdx;
  };

  watch(selectedWorkflowIndex, (newVal: number) => {
    selectedWorkflow.value = isRef(workflows.value[newVal]) ? unref(workflows.value[newVal]) : workflows.value[newVal];
  });

  return {
    selectedWorkflowIndex,
    selectedWorkflow,
    selectedWorkflowSteps,
    setWorkflow,
    workflows,
  };
};

export default useWorkflow;
