const FINANCE_ENDPOINT = import.meta.env.VITE_FINANCE_ENDPOINT

export const useFinanceDataApi = () => {
    async function onGetLenders() {
        const data = await $fetch("/api/v1/finance/getLenders", {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        })
        return data
    }

    async function onGetPayoffQuote(financeSourceId, transactionType, inquiryType, inquiryValue, customerConsent, currentOdometer = null) {
        const reqPayload = {
            financeSourceId,
            transactionType,
            inquiryType,
            inquiryValue,
            customerConsent,
        }
        if (currentOdometer) {
            reqPayload.currentOdometer = currentOdometer
        }
        const data = await $fetch(`${FINANCE_ENDPOINT}/api/v1/getPayoffQuote`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: reqPayload,
        })
        return data
    }

    return {
        onGetLenders,
        onGetPayoffQuote
    }
}