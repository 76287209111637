import { useGtm } from "@gtm-support/vue-gtm";

export const useGtmEvent = () => {
  const logger = useLogger("useGtmEvent");
  const gtm = useGtm();

  // const buildGtmEvent = (vehicleModel: any = undefined, valueObj: object | undefined = undefined) => {
  // function buildGtmEvent({ vehicleModel = undefined, valueObj = undefined }: any): any {
  const buildGtmEvent = ({ vehicleModel = undefined, valueObj = undefined }: any) => {
    if (!vehicleModel && !valueObj) {
      logger.error("vehicleModel or parameter object is required to build a gtm event");
      return;
    }

    if (vehicleModel) {
      const defaultValue = {
        workflow: vehicleModel.workflowName.value || null,
        customer: {
          customerName: vehicleModel.customerName.value || null,
          customerEmail: vehicleModel.customerEmail.value || null,
          customerPhone: vehicleModel.customerPhone.value || null,
        },
        vehicle: vehicleModel.vehicleInstance.value || null,
        finance: {
          type: vehicleModel.financeType.value || null,
          term: vehicleModel.financeTerm.value || null,
          monthlyPayment: vehicleModel.financeMontlyPayment.value || null,
          lenderId: vehicleModel.financeLenderId.value || null,
          payoffGrossAmount: vehicleModel.financePayoffGrossAmount.value || null,
          payoffNetAmount: vehicleModel.financePayoffNetAmount.value || null,
          payoffGoodThroughDate: vehicleModel.financePayoffGoodThroughDate.value || null,
        },
        // marketValuationsShown: {}, // should only be passed in the event once, not by default
        // offerDetails: {}, // should only be passed in the event on each submit, not by default
        kbbDetails: {
          value: vehicleModel.valuationPrice.value || null,
          valuationDate: vehicleModel.valuationDate.value || null,
          typicalMileage: vehicleModel.valuationTypicalMileage.value || null,
        },
      };

      if (valueObj) {
        return { ...defaultValue, ...valueObj };
      } else {
        return defaultValue;
      }
    } else if (valueObj) {
      return valueObj;
    } else {
      return undefined;
    }

    // if vehicleModel is passed, build the event with the default params, else just use the value object
    // if (vehicleModel && valueObj) {
    //   return { ...defaultValue, ...valueObj }
    // } else if (vehicleModel && !valueObj) {
    //   return defaultValue;
    // } else if (!vehicleModel && valueObj) {
    //   return valueObj;
    // } else {
    //   return undefined;
    // }
  };


  const pushEvent = (event: string, uaParams: object, value: object | undefined) => {
    gtm?.trackEvent({ event, ...uaParams, value });
  };

  return {
    buildGtmEvent,
    pushEvent,
  };
}
