import { defineStore } from "pinia";

export const useAppIntegration = defineStore("appIntegration", {
  state: () => ({
    integrationData: {},
    dealerKey: "",
    dealerHost: "",
    dealerGa4MeasurementId: "",
    dealerKbbConditionOptions: ["Very Good", "Good", "Fair"],
  }),

  getters: {},

  actions: {},
});
