<template>
  <v-btn
    class="my-2 py-5 rounded-pill h-auto w-100 text-none"
    :class="setTextColor"
    min-width="auto"
    :color="setColor"
    size="x-large"
    elevation="0"
    :variant="setVariant"
    :style="{ letterSpacing: 'inherit' }"
    :ripple="false"
    :disabled="disabled"
    :loading="loading"
    @click="handleClick"
  >
    <slot name="default" />
  </v-btn>
</template>

<script setup>
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  navBack: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
  },
  textColor: {
    type: String,
  },
  variant: {
    type: String,
  },
});

const setColor = computed(() => {
  return props.color ? props.color
    : props.navBack ? null
    : "primaryLight";

  // NOTE: above is equivalent to below (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Conditional_operator#conditional_chains)
  // if (props.color) {
  //   return props.color;
  // } else if (props.navBack) {
  //   return null;
  // } else {
  //   return "primaryLight";
  // }
});

const setTextColor = computed(() => {
  return props.textColor ? props.textColor
    : props.navBack ? "text-primaryLight"
    : "text-onPrimaryLight";
});

const setVariant = computed(() => {
  return props.variant ? props.variant
    : props.navBack ? "text"
    : "elevated";
});

const emit = defineEmits(["click"]);

const handleClick = () => {
  emit("click");
};
</script>

<style lang="scss" scoped>
.v-btn--variant-elevated:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: rgba(var(--v-theme-on-primary), 0.12);
}
.v-btn--variant-text:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background: rgba(var(--v-theme-primary40), 0.12);
}
</style>
