<template>
  <div>
    <v-alert
      v-if="store.showAlert"
      :text="store.alertMessage"
      :type="type"
      variant="flat"
      closable
      color="rgba(var(--v-theme-error80), 1)"
      class="text-errorLight"
      :class="sticky ? 'sticky-alert' : ''"
      rounded="lg"
      @click:close="handleClose"
    >
      <template #prepend>
        <v-icon :icon="mdiAlertCircle" />
      </template>
      <template #close="{ props }">
        <v-icon v-bind="props" :icon="mdiClose" />
      </template>
    </v-alert>
  </div>
</template>

<script setup>
import { mdiAlertCircle, mdiClose } from "@mdi/js";

const store = useAlertStore();

const props = defineProps({
  type: {
    type: String,
    default: "error",
  },
  clearErrorOnDismiss: {
    type: Boolean,
    default: true,
  },
  autoClose: {
    type: Boolean,
    default: false,
  },
  autoCloseDelay: {
    type: Number,
    default: 25000,
  },
  sticky: {
    type: Boolean,
    default: false,
  },
});

function handleClose() {
  if (props.clearErrorOnDismiss) {
    store.clearAlert();
  }
}

watchEffect(() => {
  if (props.autoClose && store.showAlert) {
    setTimeout(() => {
      handleClose();
    }, props.autoCloseDelay);
  }
});
</script>

<style lang="scss" scoped>
.sticky-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
</style>
