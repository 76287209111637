import logger from "~/server/services/logger";

const fetchWrapper = async (url, postData = {}) => {
  return await $fetch(url, {
    method: 'POST',
    body: postData,
    headers: { "Content-Type": "application/json" }
  })
}

export const useVehicleDataApi = () => {
  async function onLoadVehicleYears() {
    return fetchWrapper('/api/v1/vehicles/vehicleYears')
  }

  async function onLoadVehicleMakes(yearId) {
    return fetchWrapper('/api/v1/vehicles/vehicleMakes', { yearId });
  }

  async function onLoadVehicleModels(yearId, makeId) {
    return fetchWrapper("/api/v1/vehicles/vehicleModels", { yearId, makeId });
  }

  async function onLoadVehicleTrims(yearId, makeId, modelId) {
    return fetchWrapper("/api/v1/vehicles/vehicleTrims", { yearId, makeId, modelId });
  }

  async function onLoadVehiclesByYMMT(yearId, makeId, modelId, trimId) {
    return fetchWrapper("/api/v1/vehicles/vehicleByYMMT", { yearId, makeId, modelId, trimId });
  }

  async function onLoadVehicleOptions(vehicleId) {
    return fetchWrapper("/api/v1/vehicles/vehicleOptions", { vehicleId });
  }

  async function onApplyVehicleConfiguration(vehicleId, vehicleOptionIds, newOptionIds, removeOptionIds) {
    return fetchWrapper("/api/v1/vehicles/applyVehicleConfiguration", { vehicleId, vehicleOptionIds, newOptionIds, removeOptionIds, });
  }

  async function onValidateVehicleConfiguration(vehicleId, vehicleOptionIds) {
    return fetchWrapper("/api/v1/vehicles/validateVehicleConfiguration", { vehicleId, vehicleOptionIds });
  }

  async function onLookupVehicleDetailByPlate(plateState, plateNumber) {
    return fetchWrapper("/api/v1/vehicles/plateLookup", { plateState, plateNumber });
  }

  /**
   * THE ABOVE ^^^ VERSION OF onLookupVehicleDetailByPlate IS THE ONE THAT IS USED on Staging & Prod - only works there due to IP restrictions from Experian
   * THE BELOW vvv VERSION OF onLookupVehicleDetailByPlate IS THE ONE THAT IS USED on Localhost - uses sellyourcar endpoint to pull from staging
   * If you need to develop/test locally, just comment one out and uncomment the other **BUT** make sure to fix it before committing
   */

  // async function onLookupVehicleDetailByPlate(plateState, plateNumber) {
  //   const resp = await $fetch('/api/v1/vehicles/plateLookup', {
  //     method: 'POST',
  //     headers: { "Content-Type": "application/json" },
  //     body: { plateState, plateNumber },
  //     baseURL: 'https://lutherbuyscars.app.dealerapps.io'
  //   })
  //   return resp
  // }

  async function onLookupVehicleByVin(vin) {
    return fetchWrapper("/api/v1/vehicles/vehicleByVin", { vin });
  }

  async function onLoadVehicleValues(vehicleId, vehicleOptionIds, mileage, zipCode) {
    return fetchWrapper("/api/v1/vehicles/vehicleValues", { vehicleId, vehicleOptionIds, mileage, zipCode, });
  }

  async function onR4VehicleSearch(searchParams) {
    // TODO _ PORT THIS - also needs server endpoint to be created
    const r4Endpoint = process.env.VITE_R4_ENDPOINT;
    const resp = await fetch(`${r4Endpoint}/api/v1/lbc/vehicle_search`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(searchParams),
    });
    return await resp.json();
  }

  return {
    onLoadVehicleYears,
    onLoadVehicleMakes,
    onLoadVehicleModels,
    onLoadVehicleTrims,
    onLoadVehiclesByYMMT,
    onLoadVehicleOptions,
    onApplyVehicleConfiguration,
    onValidateVehicleConfiguration,
    onLookupVehicleDetailByPlate,
    onLookupVehicleByVin,
    onLoadVehicleValues,
    onR4VehicleSearch
  }
}

